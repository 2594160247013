/* eslint-disable react/prop-types */
import '../styles/globals.scss';
import 'cropperjs/dist/cropper.css';
import Link from 'next/link';

import { Provider } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { TYPE_CONSTANT } from '../constant/Web_constant';
import { useRouter } from 'next/router';
import Loading from '../components/Shared/Loading/Loading';
import Cookies from 'js-cookie';

import NoSSRIntegrations from '../integrations/NoSSRIntegrations';
import { set_root_configs } from '../constant/Root_configs_set';
import { getCookie, setCookie } from 'cookies-next';
import { push_data_layer } from '../constant/GTMDatalayer';

import { decryptDataWithAES, encryptDataWithAES } from '../constant/functions';
import DomainNotFound from '../components/Domain/DomainNotFound';
import SEO_Head from '../constant/SEO_Head';
import { initial_metadata_set } from '../utils/InitialMetadaSet';
import { uuid } from 'uuidv4';
import SubApp from '../components/Root/SubApp';

import store from '../store/store';
import FooterNote from '../components/Shared/Buttons/FooterNote';

function MyApp({ Component, pageProps, err }) {
  const router = useRouter();
  const is_draft = router?.query?.draft ? true : false;
  TYPE_CONSTANT.IS_DRAFT = router?.query?.draft ? true : false;

  //
  const [is_connected, setIs_connected] = useState(false);
  const [is_connected_error, setIs_connectedError] = useState(false);

  const [loading, setLoading] = useState(false);

  TYPE_CONSTANT.DOMAIN =
    typeof window !== 'undefined' && window.location.host
      ? window.location.host
      : '';

  // set in cookies for is_draft
  useEffect(() => {
    let inOneHour = new Date(new Date().getTime() + 60 * 60 * 1000);
    is_draft &&
      Cookies.set(`is_draft`, true, {
        expires: inOneHour,
      });
  }, [is_draft]);

  //loading for changing url
  useEffect(() => {
    const handleStart = (url) => {
      url !== router.pathname ? setLoading(true) : setLoading(false);
    };
    const handleComplete = (url) => setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);
  }, [router]);

  //GTM page view
  useEffect(() => {
    push_data_layer({
      event: 'Page View',
      data: {
        path_name: router.asPath,
      },
    });
  }, [router.asPath]);

  useEffect(() => {
    initial_metadata_set();
    // uuid set
    if (!getCookie(`${window?.location?.host}_default_uuid`)) {
      const uUid = uuid();
      setCookie(
        `${window?.location?.host}_default_uuid`,
        encryptDataWithAES(uUid)
      );
      TYPE_CONSTANT.UUID = `${window?.location?.host}_default_uuid`;
    } else {
      TYPE_CONSTANT.UUID = decryptDataWithAES(
        getCookie(`${window?.location?.host}_default_uuid`)
      );
    }

    //
    axios
      .get('/api')
      .then((res) => {
        if (typeof window !== 'undefined') {
          const response_configs = JSON.parse(
            decryptDataWithAES(res?.data?.configs)
          );

          // is window should reload
          if (res?.data?.window_should_reload) {
            window.location.reload();
          }

          //
          if (Object.keys(response_configs)?.length > 0) {
            set_root_configs({
              is_draft: router?.query?.draft,
              all_configs: response_configs,
            });
            TYPE_CONSTANT.AVAILABLE_LANGUAGES = res?.data?.tenant_languages;
            TYPE_CONSTANT.CURRENCIES = res?.data?.currencies;
            TYPE_CONSTANT.SELECTED_CURRENCY = res?.data?.selected_currency;
            TYPE_CONSTANT.CURRENCY = res?.data?.selected_currency;
            TYPE_CONSTANT.DIR = getCookie(`${TYPE_CONSTANT.DOMAIN}_direction`);

            localStorage.setItem(
              'available_languages',
              JSON.stringify(res?.data?.tenant_languages)
            );
            localStorage.setItem(
              'available_currencies',
              JSON.stringify(res?.data?.currencies)
            );

            setIs_connected(true);
          } else {
            setIs_connectedError(true);
          }
        }
      })
      .catch((error) => {
        console.log('ERROR>>>>>>>', error);
        setIs_connectedError(true);
      });
  }, []);

  return (
    <>
      {is_connected && (
        <>
          <Provider store={store}>
            <Loading loading={loading} />
            <SubApp />
            <Component {...pageProps} err={err} />
            {!router.pathname.includes('/embed') && (
              <NoSSRIntegrations
                all_integrations={TYPE_CONSTANT.ALL_INTEGRATIONS}
              />
            )}
            {/* tradly  footer note*/}
            {!TYPE_CONSTANT.GENERAL_CONFIGS?.hide_tradly_footer_note &&
              !router.pathname.includes('/embed') && <FooterNote />}
          </Provider>
        </>
      )}
      {is_connected_error && (
        <>
          <DomainNotFound />
        </>
      )}
    </>
  );
}

export default MyApp;
