/* eslint-disable @typescript-eslint/no-var-requires */
import axios from 'axios';
import { base_url } from './url';
import tradly from 'tradly';
import { TYPE_CONSTANT } from './Web_constant';
import { getCookie, setCookie } from 'cookies-next';
var CryptoJS = require('crypto-js');

export const set_sdk = async (host_name, req, res) => {
  var host = set_domain_name(req);
  const result = getCookie(`${set_host_name(req)}_default_k`, { req, res })
    ? await tradly.init
        .config({
          token: get_pk_key_from_cookie({ req: req, res: res }),
          environment: process.env.ENV,
        })
        .then(() => {
          return true;
        })
    : await axios({
        url: `${base_url()}/skua/tenants/pk_by_domain?domain=${host}&env=${
          process.env.ENV
        }`,
        method: 'GET',
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if (response.data.status) {
          // set tenant key in cookie
          setCookie(
            `${set_host_name(req)}_default_k`,
            encryptDataWithAES(response.data.data.key),
            {
              req,
              res,
            }
          );
          // set domain details in cookie
          setCookie(
            `${set_host_name(req)}_domain_details`,
            encryptDataWithAES(JSON.stringify(response.data.data.domain)),
            {
              req,
              res,
            }
          );

          // SDK initialization
          return tradly.init
            .config({
              token: response.data.data.key,
              environment: process.env.ENV,
            })
            .then(() => {
              return true;
            });
        }
      });
  return result;
};
export const set_host_name = (req) => {
  // return 'localhost:3001';
  return req.headers.host;
};

export const set_domain_name = (req) => {
  // return 'theprelovedclub.dev.tradly.co';
  return req.headers.host;
};

// seo dynamic text
export const dynamic_seo_text = ({
  dynamic_text,
  special_case,
  case_text,
  default_text,
}) => {
  if (dynamic_text) {
    const check = dynamic_text.includes(special_case);
    if (check) {
      return dynamic_text.replace(special_case, case_text);
    }
    return text;
  } else {
    return default_text;
  }
};

// AES secret key

// Encrypt data with
export function encryptDataWithAES(data) {
  return CryptoJS.AES.encrypt(data, process.env.TEMPLATE_SECRET_KEY).toString();
}

//Decrypt
export function decryptDataWithAES(data) {
  var bytes = CryptoJS.AES.decrypt(data, process.env.TEMPLATE_SECRET_KEY);
  return bytes ? bytes.toString(CryptoJS.enc.Utf8) : '';
}

// get pk key from cookie (its set )
export const get_pk_key_from_cookie = ({ req, res }) => {
  return getCookie(`${set_host_name(req)}_default_k`, { req, res })
    ? decryptDataWithAES(
        getCookie(`${set_host_name(req)}_default_k`, { req, res })
      )
    : undefined;
};
// get pk domain details from cookie
export const get_domain_details_from_cookie = ({ req, res }) => {
  return getCookie(`${set_host_name(req)}_domain_details`, {
    req,
    res,
  })
    ? JSON.parse(
        decryptDataWithAES(
          getCookie(`${set_host_name(req)}_domain_details`, { req, res })
        )
      )
    : undefined;
};
